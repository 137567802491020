import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function GoogleAnalytics() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-80QTHXLKXE");
  });

  return (
    <Helmet>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-80QTHXLKXE"
      />
    </Helmet>
  );
}

export default GoogleAnalytics;
