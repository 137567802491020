import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GoogleAnalytics from "../components/googleAnalytics";
import { PageProps, graphql, navigate } from "gatsby";
import Section from "../sections/section";

export type PageTemplateProps = PageProps<GatsbyTypes.PageTemplateQuery>;

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  useEffect(() => {
    navigate("https://lp-asset.hitotsu.co.jp", {replace: true});
  }
  , []);
  
  const title = data.contentfulLayout.title;
  const description = data.contentfulLayout.description;
  const menus = data.contentfulLayout.menu;
  const contentModule = data.contentfulLayout.contentModule;

  return (
    <Layout menus={menus}>
      <SEO title={title} description={description} />
      <GoogleAnalytics />
      {contentModule &&
        contentModule.length > 0 &&
        contentModule.map(content => (
          <Section contentModuleId={content.id} type={content.__typename} />
        ))}
    </Layout>
  );
};

export const data = graphql`
  query PageTemplate($slug: String!) {
    contentfulLayout(slug: { eq: $slug }) {
      id
      slug
      title
      description
      contentful_id
      menu {
        name
        type
        menuItems {
          id
          title
          url
        }
      }
      contentModule {
        __typename
        ... on Node {
          id
        }
      }
    }
  }
`;

export default PageTemplate;
